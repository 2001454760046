.container-raj {
  margin: 10px 25px;
}

.nav-raj {
  width: 100%;
  background-color: gray;
  height: 3rem;
}

nav {
  width: 100%;
  height: 15rem;
}

nav img {
  width: 100%;
  height: 100%;
}

.card-holder-raj {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.card-raj {
  width: 30%;
  margin: 1rem 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-raj img {
  width: 100%;
  max-height: 17rem;
}

.detail-raj {
  width: 100%;
  padding: 1rem;
}

%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}

p {
  margin: 0.5rem 0;
}

.load-raj {
  width: 100%;
  height: 1.1rem;
  background-color: gainsboro;
}

@media screen and (max-width: 860px) {
  .container-raj {
    margin: 0;
  }

  nav {
    width: 100%;
    height: 13.5rem;
  }

  .card-holder-raj {
    flex-direction: column;
  }

  .card-raj {
    display: flex;
    width: 90%;
  }

  .card-raj img {
    width: 50%;
    max-height: 15rem;
  }

  .detail-raj {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 620px) {
  .container-raj {
    margin: 0;
  }

  nav {
    width: 100%;
    height: 12rem;
  }

  .card-holder-raj {
    flex-direction: column;
  }

  .card-raj {
    width: 85%;
    margin: 1rem 0;
    display: block;
  }

  .detail-raj {
    width: 100%;
    margin: 0;
  }

  .card-raj img {
    width: 100%;
    max-height: 12rem;
  }
}
